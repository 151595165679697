import React from 'react'
import { useTable } from 'react-table'
import styles from './TableReport.module.css'

const TableReport = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({columns, data})

  return (
    <table className={styles.tableContainer} {...getTableProps()}>
      <thead className={styles.tableHeader}>
        <tr>
          {headers.map(column => (
            <th
              className={styles.tableCell}
              {...column.getHeaderProps()}
            >
              {column.render('Header')}
            </th>
          ))}
        </tr>
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)

          return (
            <tr className={styles.tableRow} {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td
                  className={styles.tableCell}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableReport
