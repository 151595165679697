import React, { Component, createContext } from 'react'
import { withRouter } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import axios from 'axios'

export const AppContext = createContext()

class AppProvider extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			loggedIn: false,
			exp: null,
			role: null,
			loading: true,
			globalData: {},
			shift: null,
			numeUtilizator: "",
			numeComplet: ""
		}
	}

	logIn = ({userName, password, shift}) => {
		const url = '/api/Account/Login'
    
    axios.post(url, {
      userName,
      password
    }).then(response => {
      if (response === undefined) {
        alert("Nume utilizator sau parola introduse eronat.")
        return
      }

      //linia urmatoare adauga implicit tokenul la toate requesturile axios de dupa autentificare
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken
      sessionStorage.setItem('userName', response.data.username)
      sessionStorage.setItem('role', JSON.stringify(response.data.role))
      sessionStorage.setItem('autok', response.data.accessToken)
			sessionStorage.setItem('numeComplet', response.data.numeComplet)
			sessionStorage.setItem('shift', JSON.stringify(shift))
			
			const decodedToken = jwtDecode(response.data.accessToken)

      this.setState({
				loggedIn: true,
				exp: decodedToken.exp,
				role: response.data.role,
				shift,
				numeUtilizator: response.data.username,
				numeComplet: response.data.numeComplet
			}, () => {
				if (response.data.role === "Administrator") {
					this.props.history.push('/home/reports')
				} else {
					this.props.history.push('/home/user-panel')
				}
			})
    }).catch(err => {
			if ([400, 401, 403].includes(err?.response?.status)) {
				alert("Nume utilizator si/sau parola incorecte!")
			}

      console.log("logIn err", err)
    })
	}

	logOut = async () => {
		const link = '/api/Account/Logout'

		try {
			if (this.state.loggedIn) await axios.post(link)
		} catch (err) {
			console.log("AppContext logOut err:", err)
		}
		
		if (this.props.history.location.pathname !== '/auth/login')
			this.props.history.push('/auth/login')

		this.setState({
			loggedIn: false,
			exp: null,
			role: null,
			loading: false,
			shift: null,
			numeUtilizator: "",
			numeComplet: ""
		}, () => {
			axios.defaults.headers.common['Authorization'] = ''
			sessionStorage.clear()
		})
	}

	verifyTokenExpiration = exp => {
		const now = Date.now()
		return now < exp * 1000
	}

	getGlobalData = () => {
		return new Promise((resolve, reject) => {
			const sizeLink = "api/DimensiuniCalitati/GetDimensiuniCalitati"
			const machineryLink = "api/Utilaje/GetUtilaje"
			const wasteLink = "api/Deseuri/GetDeseuri"
			const groupsLink = "api/Grupuri/GetGrupuri"
			const shiftsLink = "api/Schimburi/GetSchimburi"
			const usersLink = "api/Utilizatori/GetListaUtilizatori"

			axios.all([
				axios.get(sizeLink),
				axios.get(machineryLink),
				axios.get(wasteLink),
				axios.get(groupsLink),
				axios.get(shiftsLink),
				axios.get(usersLink)
			]).then(([sizeResponse, machineryResponse, wasteRespons, groupsResponse, shiftsResponse, usersResponse]) => {
				this.setState({
					globalData: {
						sizeQualities: sizeResponse.data,
						machinery: machineryResponse.data,
						waste: wasteRespons.data,
						groups: groupsResponse.data,
						shifts: shiftsResponse.data,
						users: usersResponse.data
					}
				}, () => {
					sessionStorage.setItem("globalData", JSON.stringify(this.state.globalData))
					resolve()
				})
			}).catch(err => {
				reject(err)
			})
		})
	}

	componentDidMount = () => {
		this.getGlobalData().then(() => {
			const token = sessionStorage.getItem("autok")
			const role = JSON.parse(sessionStorage.getItem("role"))
			const shift = JSON.parse(sessionStorage.getItem("shift"))
			const userName = sessionStorage.getItem("userName")
			const numeComplet = sessionStorage.getItem("numeComplet")
			const globalData = JSON.parse(sessionStorage.getItem("globalData"))

			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	
			if (token) {
				const decodedToken = jwtDecode(token)
				if (this.verifyTokenExpiration(decodedToken.exp)) {
					this.setState({
						loggedIn: true,
						exp: decodedToken.exp,
						role: role,
						loading: false,
						shift,
						numeUtilizator: userName,
						numeComplet,
						globalData
					}, () => {
						if (role === "Administrator") {
							this.props.history.push('/home/reports')
						} else {
							this.props.history.push('/home/user-panel')
						}
					})
				} else {
					this.logOut()
				}
			} else {
				this.logOut()
			}
		}).catch(err => {
			console.log("getGlobalData err:", err)
			alert("Sistemul este indisponibil momentan!")
		})
	}

	render() {
		const { children } = this.props
		const value = {
			store: this.state,
			methodes: {
				logIn: this.logIn,
				logOut: this.logOut,
				verifyTokenExpiration: this.verifyTokenExpiration
			}
		}

		return (
			<AppContext.Provider value={value}>
				{this.state.loading || children}
			</AppContext.Provider>
		)
	}
}

export default withRouter(AppProvider)
