import React, { Component } from 'react'
import { AppContext } from '../../../context/AppContext'
import Form, { Item } from 'devextreme-react/form'
import Button from 'devextreme-react/button'
import styles from '../../../styles/Auth/Login.module.css'

// import bg from '../../../styles/cherestea2.png'
import bg from '../../../styles/sigla-cherestea.png'

export default class Login extends Component {
	static contextType = AppContext

  constructor(props) {
    super(props)
  
    this.state = {
      loginData: {
        userName: '',
        password: '',
        shift: 1
      }
    }
  }

  handleLogIn = () => {
    this.context.methodes.logIn(this.state.loginData)
  }

	render() {
		return (
			<div className={styles.loginWrapper}>
        {/* <img src={bg} alt="bg" className={styles.bg1} />
        <img src={bg} alt="bg" className={styles.bg2} /> */}

        <div className={styles.formWrapper}>
          <img src={bg} alt="bg" className={styles.bg3} />

          <Form
            id="loginForm"
            labelMode="floating"
            formData={this.state.loginData}
            onEditorEnterKey={this.handleLogIn}
          >
            <Item
              dataField="userName"
              editorType="dxTextBox"
              label={{ text: 'Nume de utilizator' }}
            />

            <Item
              dataField="password"
              editorType="dxTextBox"
              label={{ text: 'Parola' }}
              editorOptions={{ mode: 'password' }}
            />

            <Item
              dataField="shift"
              editorType="dxSelectBox"
              label={{ text: 'Schimbul' }}
              editorOptions={{
                items: this.context.store.globalData.shifts,
                searchEnabled: true,
                valueExpr: 'idSchimburi',
                displayExpr: 'denumireSchimb'
              }}
            />
          </Form>

          <Button
            elementAttr={{ class: styles.loginButton }}
            text="Autentificare"
            type="default"
            stylingMode="contained"
            onClick={this.handleLogIn}
          />

          <p style={{ marginTop: 8, marginBottom: -8, textAlign: 'end', fontSize: 12 }}>SN: 343</p>
        </div>
			</div>
		)
	}
}
