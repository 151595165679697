import React, { Component } from 'react'
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export } from 'devextreme-react/chart'
import { AppContext } from '../../context/AppContext'
import axios from 'axios'
import { DateBox } from 'devextreme-react'

export default class Charts extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
  
    this.state = {
      loading: false,
      chartStart: new Date(),
      chartStop: new Date(),
      dataSource: [],
      dateList: []
    }
  }

  getData = () => {
		this.setState({
			loading: true
		}, () => {
			let dStart = this.state.chartStart
			let dStop = this.state.chartStop
	
			if (dStart > dStop) {
				dStart = dStop
	
				this.setState({
          chartStart: dStart,
          chartStop: dStop
				})
			}

      const link = `/api/Valori/AfisareValori/0/${dStart.toISOString().split("T")[0]}/${dStop.toISOString().split("T")[0]}/true`
      const dateLink = "/api/Valori/DateCuValori"

      axios.all([
				axios.get(link),
				axios.get(dateLink)
			]).then(([response, dateResponse]) => {
        this.formatData(response.data, dateResponse.data)
			}).catch(err => {
				console.log("Charts getData err:", err)

				this.setState({
					loading: false
				})
			})

      // axios.get(link).then(response => {
      //   this.formatData(response.data)
      // }).catch(err => {
			// 	console.log("Charts getData err:", err)

			// 	this.setState({
			// 		loading: false
			// 	})
			// })
		})
	}

  formatData = (rawData, dateList) => {
    const result = rawData.reduce((group, item) => {
      const { data } = item
      group[data] = group[data] ?? []
      group[data].push(item)
      return group
    }, {})

    let dataSource = []
    Object.keys(result).forEach(key => {
      const newItem = {
        data: key
      }
      
      result[key].forEach(item => {
        const totalIntrat = item.volumBustean ?? 0
        // let totalIesit = 0

        // this.context.store.globalData.sizeQualities.forEach(size => {
        //   const denProdus = `_${size.denumire}_produsVolum`
        //   const denRamas = `_${size.denumire}_ramasVolum`

        //   if (item[denProdus] !== undefined) totalIesit += item[denProdus]
        //   if (item[denRamas] !== undefined) totalIesit += item[denRamas]
        // })

        // this.context.store.globalData.waste.forEach(wa => {
        //   const den = `deseu_${wa.denumireDeseu}`
  
        //   if (item[den] !== undefined) totalIesit += item[den]
        // })

        // const totalYield = totalIesit / totalIntrat * 100
        // newItem[`schimbul${item.refSchimb}`] = Number.isNaN(totalYield) ? 0 : totalYield
        newItem[`schimbul${item.refSchimb}`] = Number.isNaN(totalIntrat) ? 0 : totalIntrat
      })

      dataSource.push(newItem)
    })

    dataSource = dataSource.sort((a, b) => {
      const aTime = new Date(a.data).getTime()
      const bTime = new Date(b.data).getTime()

      if (aTime < bTime) return -1
      if (aTime > bTime) return 1

      return 0
    }).map(d => {
      return { ...d, data: new Date(d.data).toLocaleDateString("ro-RO") }
    })

    this.setState({
      dataSource,
      dateList: dateList.map(d => (new Date(d)).getTime()),
      loading: false
    })
  }

  componentDidMount = () => {
    this.getData()
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DateBox
            style={{ marginRight: 8 }}
            label="Data inceput"
            labelMode="floating"
            max={this.state.chartStop}
            value={this.state.chartStart}
            onValueChanged={(e) => {
              this.setState({
                chartStart: e.value
              }, () => {
                this.getData()
              })
            }}
            calendarOptions={{
              cellTemplate: (e) => {
                if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
                else return '<span>' + e.text + '</span>'
              }
            }}
          />

          <DateBox
            style={{ marginRight: 8 }}
            label="Data sfarsit"
            labelMode="floating"
            max={new Date()}
            value={this.state.chartStop}
            onValueChanged={(e) => {
              this.setState({
                chartStop: e.value
              }, () => {
                this.getData()
              })
            }}
            calendarOptions={{
              cellTemplate: (e) => {
                if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
                else return '<span>' + e.text + '</span>'
              }
            }}
          />
        </div>

        <Chart
          id="chart"
          style={{ height: 'calc(100% - 30px)' }}
          // title="Grafic productie schimburi pe zile"
          title="Volum bustean pe schimburi pe zile"
          dataSource={this.state.dataSource}
        >
          <CommonSeriesSettings
            argumentField="data"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </CommonSeriesSettings>

          {this.context.store.globalData.shifts.map((shift, index) => (
            <Series
              key={`series${index}`}
              valueField={shift.denumireSchimb.replace(' ', '').toLowerCase()}
              name={shift.denumireSchimb.replace(' ', '').toLowerCase()}
            />
          ))}
          {/* <Series
            argumentField="state"
            valueField="year2018"
            name="2018"
          />
          <Series
            valueField="year2017"
            name="2017"
          />
          <Series
            valueField="year2016"
            name="2016"
          /> */}
          <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
          <Export enabled={true} />
        </Chart>
      </div>
    )
  }
}
