import React, { Component } from 'react'
import { AppContext } from '../../context/AppContext'
import DataGrid, { Column, RequiredRule, Editing, Toolbar, Item, Scrolling } from 'devextreme-react/data-grid'
import { LoadPanel } from 'devextreme-react/load-panel'
import axios from 'axios'

export default class AppSettings extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
  
    this.state = {
      loading: false,
      dataSourceSize: [],
      dataSourceShift: []
    }
  }

  getData = () => {
    this.setState({
      loading: true
    }, () => {
      const sizeLink = "/api/DimensiuniCalitati/GetDimensiuniCalitati"
      const shiftLink = "/api/Schimburi/GetSchimburi"
  
      axios.all([
        axios.get(sizeLink),
        axios.get(shiftLink)
      ]).then(([responseSize, responseShift]) => {
        this.setState({
          dataSourceSize: responseSize.data,
          dataSourceShift: responseShift.data,
          loading: false
        })
      }).catch(err => {
        console.log("AppSettings getData err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleUpdateSize = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = `/api/DimensiuniCalitati/PutDimensiuniCalitati/${data.idDimensiuniCalitati}`

      axios.put(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleUpdateSize err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleAddSize = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = "/api/DimensiuniCalitati/PostDimensiuniCalitati"

      if (data.idDimensiuniCalitati !== undefined) delete data.idDimensiuniCalitati
  
      axios.post(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleAddSize err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleDeleteSize = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = `/api/DimensiuniCalitati/DeleteDimensiuniCalitati/${data.idDimensiuniCalitati}`

      axios.delete(link).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleDeleteSize err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }


  handleUpdateSfift = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = `/api/Schimburi/PutSchimburi/${data.idSchimburi}`

      axios.put(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleUpdateSfift err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleAddShift = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = "/api/Schimburi/PostSchimburi"

      if (data.idSchimburi !== undefined) delete data.idSchimburi
  
      axios.post(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleAddShift err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleDeleteShift = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = `/api/Schimburi/DeleteSchimburi/${data.idSchimburi}`

      axios.delete(link).then(response => {
        this.getData()
      }).catch(err => {
        console.log("AppSettings handleDeleteShift err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  componentDidMount = () => {
    this.getData()
  }

  render() {
    return (
      <div style={{ display: 'flex', height: "100%" }}>
        <DataGrid
          style={{ paddingRight: 8 }}
          height="100%"
          dataSource={this.state.dataSourceSize}
          keyExpr="idDimensiuniCalitati"
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled={true}
          onRowUpdated={(e) => {
            this.handleUpdateSize(e.data)
          }}
          onRowInserted={(e) => {
            this.handleAddSize(e.data)
          }}
          onRowRemoved={(e) => {
            this.handleDeleteSize(e.data)
          }}
        >
          <Toolbar>
            <Item location="before">
              Dimensiuni/Calitati
            </Item>

            <Item name="addRowButton" />
          </Toolbar>

          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
            useIcons={true}
          />

          <Column caption="Dimensiune" dataField="dimensiune" alignment="center">
            <RequiredRule />
          </Column>

          <Column caption="Calitate" dataField="calitate" alignment="center">
            <RequiredRule />
          </Column>

            <Column caption="Numar randuri" dataField="numarRanduri" alignment="center">
                <RequiredRule />
            </Column>

            <Column caption="Volum pachet" dataField="volumPachet" alignment="center">
                <RequiredRule />
            </Column>

          <Scrolling mode="virtual" />
        </DataGrid>

        <DataGrid
          style={{ paddingLeft: 8 }}
          height="100%"
          dataSource={this.state.dataSourceShift}
          keyExpr="idSchimburi"
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled={true}
          onRowUpdated={(e) => {
            this.handleUpdateSfift(e.data)
          }}
          onRowInserted={(e) => {
            this.handleAddShift(e.data)
          }}
          onRowRemoved={(e) => {
            this.handleDeleteShift(e.data)
          }}
        >
          <Toolbar>
            <Item location="before">
              Schimburi
            </Item>

            <Item name="addRowButton" />
          </Toolbar>

          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
            useIcons={true}
          />

          <Column caption="Denumire" dataField="denumireSchimb" alignment="center">
            <RequiredRule />
          </Column>

          <Scrolling mode="virtual" />
        </DataGrid>

        <LoadPanel
          position={{ my: "center", at: "center" }}
          visible={this.state.loading}
          shading={true}
          showPane={true}
        />
      </div>
    )
  }
}
