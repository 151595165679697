import React, { Component } from 'react'
import { AppContext } from '../../../context/AppContext'
import Form, { EmptyItem, GroupItem, SimpleItem } from 'devextreme-react/form'
import { Button, DateBox, ScrollView, SelectBox, TagBox } from 'devextreme-react'
import { LoadPanel } from 'devextreme-react/load-panel'
import { alert } from "devextreme/ui/dialog"
import axios from 'axios'

import styles from './UserPanel.module.css'

export default class UserPanel extends Component {
	static contextType = AppContext

	constructor(props) {
		super(props)
	
		this.state = {
			userData: {},
			calitatiInLucru: [],
			overYield: false,
			loading: false,

			data: new Date(),
			schimbul: 1
		}
	}

	getData = () => {
		this.setState({
			loading: true
		}, () => {
			const link = `/api/Valori/AfisareValori/${this.state.schimbul}/${this.state.data.toISOString().split("T")[0]}/${this.state.data.toISOString().split("T")[0]}/false`
			const calitatiInLucruLink = `/api/CalitatiInLucru/GetCalitatiInLucru/${this.state.schimbul}/${this.state.data.toISOString().split("T")[0]}`

			axios.get(calitatiInLucruLink).then(responseCalitatiInLucru => {
				axios.get(link).then(response => {
					this.setState({
						userData: response.data[0],
						calitatiInLucru: responseCalitatiInLucru.data,
						loading: false
					}, () => {
						this.checkYield()
					})
				}).catch(err => {
					console.log("UserPanel getData err:", err)
	
					this.setState({
						loading: false
					})
				})
			}).catch(err => {
				console.log("UserPanel getData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

	handleUpdateCalitatiInLucru = (e) => {
		if (JSON.stringify(e.value) !== JSON.stringify(this.state.calitatiInLucru)) {
			this.setState({
				loading: true
			}, () => {
				const link = `/api/CalitatiInLucru/ActualizareCalitatiInLucru/${this.state.schimbul}/${this.state.data.toISOString().split("T")[0]}`

				axios.put(link, e.value).then(response => {
					this.setState({
						calitatiInLucru: response.data,
						loading: false
					})
				}).catch(err => {
					console.log("UserPanel handleUpdateCalitatiInLucru err:", err)

					this.setState({
						loading: false
					})
				})
			})
		}
	}

	handleSaveData = () => {
		this.setState({
			loading: true
		}, () => {
			console.log("userData:", this.state.userData)

			const currentDate = new Date()
			const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
			const dateWithoutTime = new Date(this.state.data.getFullYear(), this.state.data.getMonth(), this.state.data.getDate())

			const link = `/api/Valori/EditareAdaugareValori/${(dateWithoutTime.getTime() < currentDateWithoutTime.getTime() || this.state.schimbul !== this.context.store.shift)}`
			const linkRamase = "/api/RanduriAnterioare/InsertRanduriAnterioare"

			let totalRamas = 0
			this.context.store.globalData.sizeQualities.forEach(size => {
				const denRamas = `_${size.denumire}_ramasVolum`

				if (this.state.userData[denRamas] !== undefined) totalRamas += this.state.userData[denRamas]
			})

			const ramasePayload = {
				volumRanduriAnterioare: totalRamas,
				dataRanduriAnterioare: this.state.userData["data"],
				schimbRanduriAnterioare: this.state.userData["refSchimb"]
			}

			axios.post(link, this.state.userData).then(response => {
				axios.post(linkRamase, ramasePayload).then(r => {
					this.getData()
				}).catch(err => {
					console.log("UserPanel handleSaveData err:", err)

					this.setState({
						loading: false
					})
				})
			}).catch(err => {
				console.log("UserPanel handleSaveData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

	checkYield = () => {
		console.log("userData:", this.state.userData)
		const totalIntrat = this.state.userData.volumBustean
		let totalRezultat = 0

		this.context.store.globalData.sizeQualities.forEach(size => {
			const denProdus = `_${size.denumire}_produsVolum`
			const denRamas = `_${size.denumire}_ramasVolum`

			if (this.state.userData[denProdus] !== undefined) totalRezultat += this.state.userData[denProdus]
			if (this.state.userData[denRamas] !== undefined) totalRezultat += this.state.userData[denRamas]
		})

		this.context.store.globalData.waste.forEach(wa => {
			const den = `deseu_${wa.denumireDeseu}`

			if (this.state.userData[den] !== undefined) totalRezultat += this.state.userData[den]
		})

		this.setState({
			overYield: totalRezultat > totalIntrat
		})
	}

	componentDidMount = () => {
		this.setState({
			schimbul: this.context.store.shift
		}, () => {
			this.getData()
		})
	}

	// shouldComponentUpdate = (nextProps, nextState) => {
	// 	if (JSON.stringify(this.state.userData) === JSON.stringify(nextState.userData)) {
	// 		return false
	// 	}

	// 	return true
	// }

	render() {
		return (
			<ScrollView>
				<div className={styles.controlPanel}>
					<TagBox
						className={styles.tagBox}
						dataSource={this.context.store.globalData.sizeQualities}
						valueExpr="idDimensiuniCalitati"
						displayExpr="denumire"
						showSelectionControls={true}
						applyValueMode="useButtons"
						label="Alege dimeniuni-calitati..."
						labelMode="floating"
						value={this.state.calitatiInLucru}
						onValueChanged={this.handleUpdateCalitatiInLucru}
						disabled={this.context.store.role === "Utilizator"}
					/>

					{/* {this.state.overYield && (
						<div className={styles.overYield}>Randamentul este peste 100%!</div>
					)} */}

					<div style={{ display: 'flex' }}>
						<DateBox
							style={{ marginRight: 8 }}
							type="date"
							value={this.state.data}
							max={new Date()}
							// min={}
							onValueChanged={e => {
								this.setState({
									data: e.value
								})
							}}
						/>

						<SelectBox
							style={{ marginRight: 8 }}
							dataSource={this.context.store.globalData.shifts}
							valueExpr="idSchimburi"
							displayExpr="denumireSchimb"
							value={this.state.schimbul}
							onValueChanged={e => {
								this.setState({
									schimbul: e.value
								})
							}}
						/>

						<Button
							text="Schimba"
							icon="save"
							type="default"
							onClick={this.getData}
						/>
					</div>

					<Button
						text="Salveaza"
						icon="save"
						type="default"
						disabled={this.context.store.role === "Utilizator"}
						onClick={this.handleSaveData}
					/>
				</div>
				
				<Form
					id="userForm"
					labelMode="floating"
					formData={this.state.userData}
					disabled={this.context.store.role === "Utilizator"}
					colCount={3}
				>
					<GroupItem caption="Alte informarii" colCount={3} colSpan={3}>
						<SimpleItem
							dataField="sefSchimb"
							label={{ text: 'Sef schimb' }}
							editorType="dxSelectBox"
							editorOptions={{ dataSource: this.context.store.globalData.users, displayExpr: "numeComplet", valueExpr: "idUtilizatori" }}
							colSpan={1}
						/>

						<EmptyItem colSpan={2} />
					</GroupItem>
					
					<GroupItem caption="Bustean taiat" colCount={2} colSpan={3}>
						<SimpleItem
							dataField="bucatiBustean"
							label={{ text: 'Bucati' }}
							editorType="dxNumberBox"
							editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
						/>

						<SimpleItem
							dataField="volumBustean"
							label={{ text: 'Volum' }}
							editorType="dxNumberBox"
							editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
						/>
					</GroupItem>

					{this.state.calitatiInLucru.length > 0 &&
						<GroupItem caption="Cherestea" colSpan={3}>
							{this.context.store.globalData.sizeQualities.filter(f => this.state.calitatiInLucru.includes(f.idDimensiuniCalitati)).map((item, index) => (
								<GroupItem
									cssClass={styles.clasatest}
									key={`${item.denumire}-${index}`}
									caption={item.denumire}
									colCount={4}
								>
									<SimpleItem
										dataField={`_${item.denumire}_produsPachete`}
										label={{ text: 'Produs - pachete' }}
										editorType="dxNumberBox"
										editorOptions={{
											step: 1,
											min: 0,
											precision: 0,
											format: "#0",
											onValueChanged: (e) => {
												if ((e.value * item.volumPachet) !== this.state.userData[`_${item.denumire}_produsVolum`]) {
													this.setState({
														userData: {
															...this.state.userData,
															// [`_${item.denumire}_produsPachete`]: e.value,
															[`_${item.denumire}_produsVolum`]: e.value * item.volumPachet
														}
													})
												}
											}
										}}
									/>

									<SimpleItem
										dataField={`_${item.denumire}_produsVolum`}
										label={{ text: 'Produs - volum pachete' }}
										editorType="dxNumberBox"
										editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00", readOnly: true }}
									/>

									<SimpleItem
										dataField={`_${item.denumire}_ramasBuc`}
										label={{ text: 'Ramas - randuri' }}
										editorType="dxNumberBox"
										editorOptions={{
											step: 1,
											min: 0,
											// max: item.numarRanduri - 1,
											precision: 0,
											format: "#0",
											onValueChanged: (e) => {
												if ((item.volumPachet / item.numarRanduri * e.value) !== this.state.userData[`_${item.denumire}_ramasVolum`]) {
													this.setState({
														userData: {
															...this.state.userData,
															// [`_${item.denumire}_ramasBuc`]: e.value,
															[`_${item.denumire}_ramasVolum`]: item.volumPachet / item.numarRanduri * e.value
														}
													}, () => {
														if (this.state.userData[`_${item.denumire}_ramasBuc`] > item.numarRanduri) {
															alert("Numarul de randuri ramase nu poate depasi numarul de randuri dintr-un pachet complet.", "Atentie")

															if (this.state.userData[`_${item.denumire}_ramasBuc`] !== 0 || this.state.userData[`_${item.denumire}_ramasVolum`] !== 0)
															this.setState({
																userData: {
																	...this.state.userData,
																	[`_${item.denumire}_ramasBuc`]: 0,
																	[`_${item.denumire}_ramasVolum`]: 0
																}
															})
														}
													})
												}
											}
										}}
									/>

									<SimpleItem
										dataField={`_${item.denumire}_ramasVolum`}
										label={{ text: 'Ramas - volum randuri' }}
										editorType="dxNumberBox"
										editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00", readOnly: true }}
									/>
								</GroupItem>
							))}
						</GroupItem>
					}
					

					<GroupItem caption="Panze utilizate" colCount={3} colSpan={3}>
						{this.context.store.globalData.machinery.map((item, index) => (
							<SimpleItem
								key={`${item.denumireUtilaj}-${index}`}
								dataField={`utilaj_${item.denumireUtilaj}`}
								label={{ text: item.denumireUtilaj }}
								editorType="dxNumberBox"
								editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
							/>
						))}
					</GroupItem>

					<GroupItem caption="Deseuri" colCount={3} colSpan={3}>
						{this.context.store.globalData.waste.map((item, index) => (
							<SimpleItem
								key={`${item.denumireDeseu}-${index}`}
								dataField={`deseu_${item.denumireDeseu}`}
								label={{ text: item.denumireDeseu }}
								editorType="dxNumberBox"
								editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
							/>
						))}
					</GroupItem>

					<GroupItem caption="Alte informatii" colSpan={3}>
						<SimpleItem
							dataField="obs"
							label={{ text: 'Observatie' }}
							editorType="dxTextArea"
							editorOptions={{ minHeight: 100 }}
							colSpan={3}
						/>
					</GroupItem>
				</Form>

				<LoadPanel
          position={{ my: "center", at: "center" }}
          visible={this.state.loading}
          shading={true}
          showPane={true}
        />
			</ScrollView>
		)
	}
}
