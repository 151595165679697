import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './components/Home/Home'
import Auth from './components/Auth/Auth'

import { locale, loadMessages } from 'devextreme/localization'
import devextremeRo from './styles/ro.json'

export default class App extends Component {
  static displayName = App.name

  constructor(props) {
    super(props)
  
    loadMessages(devextremeRo)
    locale("ro")
  }

  render () {
    return (
      <Switch>
        <Route path='/home' component={Home} />
        <Route path='/auth' component={Auth} />
        <Redirect exact from='/' to='/home' />
      </Switch>
    )
  }
}
