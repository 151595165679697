import React, { Component } from 'react'
import { AppContext } from '../../context/AppContext'
import TableReport from './TableReport/TableReport'
import { Button, CheckBox, DateBox, ScrollView, SelectBox, TextBox } from 'devextreme-react'
import { LoadPanel } from 'devextreme-react/load-panel'
import axios from 'axios'

export default class Reports extends Component {
  static contextType = AppContext
  
  constructor(props) {
    super(props)
  
    const currentDate = new Date()

    this.state = {
      loading: false,
      reportInfo: {
        reportType: 1,
        reportInterval: false,
        reportDate: new Date(/*currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1*/),
        reportStart: new Date(),
        reportStop: new Date(),
        reportShift: 0,
        reportYield: "",
        reportWasteYield: "",
        reportAYield: "",
        reportBYield: ""
      },
      columns: [
        { Header: 'Denumire', accessor: 'den' },
        { Header: 'Nr./Buc.', accessor: 'buc' },
        { Header: 'Volum (mc)', accessor: 'vol' },
        // { Header: 'Nr./Buc. ramas', accessor: 'bucRamas' },
        // { Header: 'Volum ramas (mc)', accessor: 'volRamas' }
      ],
      data: [],
      dateList: []
    }
  }

  getData = () => {
		this.setState({
			loading: true
		}, () => {
      let link = ""
      const dateLink = "/api/Valori/DateCuValori"
      let randuriAnterioareLink = ""
			let dStart = this.state.reportInfo.reportStart
			let dStop = this.state.reportInfo.reportStop
	
			if (dStart > dStop) {
				dStart = dStop
	
				this.setState({
					adminDateInfo: {
						dataStart: dStart,
						dataStop: dStop
					}
				})
			}

      if (this.state.reportInfo.reportInterval) {
          link = `/api/Valori/AfisareValori/${this.state.reportInfo.reportShift}/${dStart.toISOString().split("T")[0]}/${dStop.toISOString().split("T")[0]}/true`
          randuriAnterioareLink = `/api/RanduriAnterioare/GetLastRanduriAnterioare/${this.state.reportInfo.reportShift}/${dStop.toISOString().split("T")[0]}`
      } else {
          link = `/api/Valori/AfisareValori/${this.state.reportInfo.reportShift}/${this.state.reportInfo.reportDate.toISOString().split("T")[0]}/${this.state.reportInfo.reportDate.toISOString().split("T")[0]}/true`
          randuriAnterioareLink = `/api/RanduriAnterioare/GetLastRanduriAnterioare/${this.state.reportInfo.reportShift}/${this.state.reportInfo.reportDate.toISOString().split("T")[0]}`
      }

      axios.all([
				axios.get(link),
                axios.get(dateLink)
      ]).then(([response, dateResponse]) => {
          if (this.state.reportInfo.reportShift === 0) {
              this.formatData(response.data, dateResponse.data)
          } else {
              axios.get(randuriAnterioareLink).then(responseRand => {
                  this.formatData(response.data, dateResponse.data, responseRand.data)
              }).catch(err => {
                  console.log("Reports getData err:", err)

                  this.setState({
                      loading: false
                  })
              })
          }
          
			}).catch(err => {
				console.log("Reports getData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

  formatData = (data, dateList, rand = 0) => {
    console.log("rand:", rand)
    const dataSum = {
      bucatiBustean: 0,
      volumBustean: 0
    }

    let totalCherestea = 0
    let totalRamas = 0
    let totalDeseuri = 0
    let totalA = 0
    let totalB = 0

    data.forEach((item, index) => {
      if (item.bucatiBustean !== undefined && dataSum.bucatiBustean === undefined) dataSum.bucatiBustean = 0
      if (item.volumBustean !== undefined && dataSum.volumBustean === undefined) dataSum.volumBustean = 0

      if (item.bucatiBustean !== undefined) dataSum.bucatiBustean += item.bucatiBustean
      if (item.volumBustean !== undefined) dataSum.volumBustean += item.volumBustean

      this.context.store.globalData.sizeQualities.forEach((size, index) => {
        const den1 = `_${size.denumire}_produsPachete`
        const den2 = `_${size.denumire}_produsVolum`
        const den3 = `_${size.denumire}_ramasBuc`
        const den4 = `_${size.denumire}_ramasVolum`

        if (item[den1] !== undefined && dataSum[den1] === undefined) dataSum[den1] = 0
        if (item[den2] !== undefined && dataSum[den2] === undefined) dataSum[den2] = 0
        if (item[den3] !== undefined && dataSum[den3] === undefined) dataSum[den3] = 0
        if (item[den4] !== undefined && dataSum[den4] === undefined) dataSum[den4] = 0

        if (item[den1] !== undefined) dataSum[den1] += item[den1]
        if (item[den2] !== undefined) dataSum[den2] += item[den2]
        if (item[den3] !== undefined) dataSum[den3] += item[den3]
        if (item[den4] !== undefined) dataSum[den4] += item[den4]

        if (item[den2] !== undefined) {
          totalCherestea += item[den2]

          if (size.calitate.includes("A")) {
            totalA += item[den2]
          } else if (size.calitate.includes("B")) {
            totalB += item[den2]
          } else {

          }
        }

        if (item[den4] !== undefined) totalRamas += item[den4]
      })

      this.context.store.globalData.waste.forEach((wa, index) => {
        const den = `deseu_${wa.denumireDeseu}`

        if (item[den] !== undefined && dataSum[den] === undefined) dataSum[den] = 0
          if (item[den] !== undefined) dataSum[den] += item[den]

          if (wa.denumireDeseu !== "Uscati" && item[den] !== undefined) totalDeseuri += item[den]
      })

      this.context.store.globalData.machinery.forEach((mac, index) => {
        const den = `utilaj_${mac.denumireUtilaj}`
  
        if (item[den] !== undefined && dataSum[den] === undefined) dataSum[den] = 0
        if (item[den] !== undefined) dataSum[den] += item[den]
      })
    })

    const dataArray = [
      { den: 'Bustean taiat', buc: dataSum.bucatiBustean, vol: dataSum.volumBustean?.toFixed(2), bucRamas: "-", volRamas: "-" }
    ]

    this.context.store.globalData.sizeQualities.forEach((size, index) => {
      const key1 = `_${size.denumire}_produsPachete`
      const key2 = `_${size.denumire}_produsVolum`
      const key3 = `_${size.denumire}_ramasBuc`
      const key4 = `_${size.denumire}_ramasVolum`

      if (dataSum[key1] !== undefined || dataSum[key2] !== undefined || dataSum[key3] !== undefined || dataSum[key4] !== undefined) {
        dataArray.push({ den: `Cherestea grosime ${size.dimensiune} mm - ${size.calitate}`, buc: dataSum[key1] ?? 0, vol: dataSum[key2]?.toFixed(2) ?? 0, bucRamas: dataSum[key3] ?? 0, volRamas: dataSum[key4]?.toFixed(2) ?? 0 })
      }
    })

    this.context.store.globalData.waste.forEach((wa, index) => {
      const key = `deseu_${wa.denumireDeseu}`

      if (dataSum[key] !== undefined) dataArray.push({ den: wa.denumireDeseu, buc: "-", vol: dataSum[key], bucRamas: "-", volRamas: "-" })
    })

    this.context.store.globalData.machinery.forEach((mac, index) => {
      const key = `utilaj_${mac.denumireUtilaj}`

      if (dataSum[key] !== undefined) dataArray.push({ den: mac.denumireUtilaj, buc: dataSum[key], vol: "-", bucRamas: "-", volRamas: "-" })
    })

    let reportYield = 0
    const reportWasteYield = totalDeseuri / dataSum.volumBustean * 100
    const reportAYield = totalA / totalCherestea * 100
    const reportBYield = totalB / totalCherestea * 100

    if (this.state.reportInfo.reportInterval) {
      reportYield = ((totalCherestea - rand) / dataSum.volumBustean) * 100
    } else {
      reportYield = ((totalRamas - rand + totalCherestea) / dataSum.volumBustean) * 100
    }

    this.setState({
      data: dataArray,
      dateList: dateList.map(d => (new Date(d)).getTime()),
      reportInfo: {
        ...this.state.reportInfo,
        reportYield: Number.isNaN(reportYield) ? "Nu se poate calcula" : `${reportYield.toFixed(2)}%`,
        reportWasteYield: Number.isNaN(reportWasteYield) ? "Nu se poate calcula" : `${reportWasteYield.toFixed(2)}%`,
        reportAYield: Number.isNaN(reportAYield) ? "Nu se poate calcula" : `${reportAYield.toFixed(2)}%`,
        reportBYield: Number.isNaN(reportBYield) ? "Nu se poate calcula" : `${reportBYield.toFixed(2)}%`
      },
      loading: false
    })
  }

  componentDidMount = () => {
    this.getData()
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <div id="reportForm" style={{ padding: 8, marginBottom: 16, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
					{/* <Item
						dataField="reportType"
						editorType="dxSelectBox"
						label={{ text: 'Raportul' }}
						editorOptions={{
							items: [{ id: 1, name: 'Raport 1' }, { id: 2, name: 'Raport 2' }],
							valueExpr: 'id',
							displayExpr: 'name'
						}}
					/> */}

          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <CheckBox
              style={{ marginRight: 8 }}
              text="Interval"
              value={this.state.reportInfo.reportInterval}
              onValueChanged={(e) => {
                this.setState({
                  reportInfo: {
                    ...this.state.reportInfo,
                    reportInterval: e.value
                  }
                }, () => {
                  this.getData()
                })
              }}
            />

            {this.state.reportInfo.reportInterval ? (
              <>
                <DateBox
                  style={{ marginRight: 8 }}
                  label="Data inceput"
                  labelMode="floating"
                  max={this.state.reportInfo.reportStop}
                  value={this.state.reportInfo.reportStart}
                  onValueChanged={(e) => {
                    this.setState({
                      reportInfo: {
                        ...this.state.reportInfo,
                        reportStart: e.value
                      }
                    }, () => {
                      this.getData()
                    })
                  }}
                  calendarOptions={{
                    cellTemplate: (e) => {
                      if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
                      else return '<span>' + e.text + '</span>'
                    }
                  }}
                />

                <DateBox
                  style={{ marginRight: 8 }}
                  label="Data sfarsit"
                  labelMode="floating"
                  max={new Date()}
                  value={this.state.reportInfo.reportStop}
                  onValueChanged={(e) => {
                    this.setState({
                      reportInfo: {
                        ...this.state.reportInfo,
                        reportStop: e.value
                      }
                    }, () => {
                      this.getData()
                    })
                  }}
                  calendarOptions={{
                    cellTemplate: (e) => {
                      if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
                      else return '<span>' + e.text + '</span>'
                    }
                  }}
                />
              </>
            ) : (
              <DateBox
                style={{ marginRight: 8 }}
                label="Data"
                labelMode="floating"
                max={new Date()}
                value={this.state.reportInfo.reportDate}
                onValueChanged={(e) => {
                  this.setState({
                    reportInfo: {
                      ...this.state.reportInfo,
                      reportDate: e.value
                    }
                  }, () => {
                    this.getData()
                  })
                }}
                calendarOptions={{
                  cellTemplate: (e) => {
                    if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
                    else return '<span>' + e.text + '</span>'
                  }
                }}
              />
            )}

            <SelectBox
              style={{ marginRight: 8 }}
              label="Schimbul"
              labelMode="floating"
              items={[{ idSchimburi: 0, denumireSchimb: 'Toate schimburile' }, ...this.context.store.globalData.shifts]}
              valueExpr="idSchimburi"
							displayExpr="denumireSchimb"
              value={this.state.reportInfo.reportShift}
              onValueChanged={(e) => {
                this.setState({
                  reportInfo: {
                    ...this.state.reportInfo,
                    reportShift: e.value
                  }
                }, () => {
                  this.getData()
                })
              }}
            />

            <TextBox
              style={{ marginRight: 8 }}
              label="Randament"
              labelMode="floating"
              readOnly={true}
              value={this.state.reportInfo.reportYield}
            />

            <TextBox
              style={{ marginRight: 8 }}
              label="Procent laturoaie"
              labelMode="floating"
              readOnly={true}
              value={this.state.reportInfo.reportWasteYield}
            />

            <TextBox
              style={{ marginRight: 8 }}
              label="Procent A"
              labelMode="floating"
              readOnly={true}
              value={this.state.reportInfo.reportAYield}
            />

            <TextBox
              label="Procent B"
              labelMode="floating"
              readOnly={true}
              value={this.state.reportInfo.reportBYield}
            />  
          </div>

          <div style={{ display: 'flex' }}>
            {/* <Button
              style={{ marginRight: 8 }}
              text="Afiseaza"
              type="default"
              onClick={() => {
                this.getData()
              }}
            /> */}

            <Button
              style={{ marginLeft: 8 }}
              text="Printeaza"
              type="default"
              onClick={() => {
                const table = document.getElementById("printTable")
                const head = document.getElementsByTagName("head")[0].cloneNode(true)
                const myWindow = window.open("", "Printare raport")

                const css = '@page { size: landscape; }'
                const printStyle = document.createElement('style')
                printStyle.type = 'text/css'
                printStyle.media = 'print'

                if (printStyle.styleSheet) printStyle.styleSheet.cssText = css
                else printStyle.appendChild(document.createTextNode(css))

                head.appendChild(printStyle)


                const css2 = 'html, body { height: auto } #printTable table thead { border-bottom: 1px solid black; } #printHeader { display: flex; justify-content: space-around; font-size: 20px; margin: 10px 0 20px; }'
                const viewStyle = document.createElement('style')
                viewStyle.type = 'text/css'

                if (viewStyle.styleSheet) viewStyle.styleSheet.cssText = css2
                else viewStyle.appendChild(document.createTextNode(css2))

                head.appendChild(viewStyle)

                const shifts = [{ idSchimburi: 0, denumireSchimb: 'Toate schimburile' }, ...this.context.store.globalData.shifts]
                let selectedShift = shifts.find(f => f.idSchimburi === this.state.reportInfo.reportShift)

                if (selectedShift) {
                  selectedShift = selectedShift.denumireSchimb.replace("Schimbul ", "")
                }

                let html = `<div id="printHeader">
                  <div>Raport de productie din ${this.state.reportInfo.reportInterval ? "intervalul" : "data"}: <u>${this.state.reportInfo.reportInterval ? this.state.reportInfo.reportStart.toLocaleDateString() + " - " + this.state.reportInfo.reportStop.toLocaleDateString() : this.state.reportInfo.reportDate.toLocaleDateString()}</u></div>
                  <div>Schimbul: <u>${selectedShift}</u></div>
                  <div>Randament: <u>${this.state.reportInfo.reportYield}</u></div>
                    <div>Procent laturoaie: <u>${this.state.reportInfo.reportWasteYield}</u></div>
                </div>`

                myWindow.document.write(head.outerHTML + html + table.outerHTML)
                myWindow.document.close()
                myWindow.addEventListener('load', () => {myWindow.print()})
              }}
            />
          </div>
				</div>

        <ScrollView style={{ height: "calc(100% - 74px)" }}>
          <div id="printTable" style={{ height: "auto" }}>
            <TableReport columns={this.state.columns} data={this.state.data} />
          </div>
        </ScrollView>

        <LoadPanel
          position={{ my: "center", at: "center" }}
          visible={this.state.loading}
          shading={true}
          showPane={true}
        />
      </div>
    )
  }
}
