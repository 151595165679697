import './styles/reset.css'
import 'devextreme/dist/css/dx.light.css'
import './styles/global.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import AppProvider from './context/AppContext'
import App from './App'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>,
  rootElement
)
