import React, { Component } from 'react'
import { AppContext } from '../../context/AppContext'
import DataGrid, { Column, Lookup, Editing, RequiredRule, Toolbar, Item, Scrolling } from 'devextreme-react/data-grid'
import { LoadPanel } from 'devextreme-react/load-panel'
import axios from 'axios'

class UserSettings extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
  
    this.state = {
      dataSource: [],
      loading: false
    }
  }

  getData = () => {
    this.setState({
      loading: true
    }, () => {
      const link = "/api/Utilizatori/GetUtilizatori"

      axios.get(link).then(response => {
        this.setState({
          dataSource: response.data,
          loading: false
        })
      }).catch(err => {
        console.log("UserSettings getData err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleUpdateUser = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = `/api/Utilizatori/PutUtilizatori/${data.idUtilizatori}`

      data.stare = data.stare ? 1 : 0
  
      axios.put(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("UserSettings handleUpdateUser err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  handleAddUser = (data) => {
    this.setState({
      loading: true
    }, () => {
      const link = "/api/Utilizatori/PostUtilizatori"

      if (data.idUtilizatori !== undefined) delete data.idUtilizatori
      data.stare = data.stare ? 1 : 0
  
      axios.post(link, data).then(response => {
        this.getData()
      }).catch(err => {
        console.log("UserSettings handleAddUser err:", err)

        this.setState({
          loading: false
        })
      })
    })
  }

  componentDidMount = () => {
    this.getData()
  }

  render() {
    return (
      <>
        <DataGrid
          height="100%"
          dataSource={this.state.dataSource}
          keyExpr="idUtilizatori"
          columnAutoWidth={true}
          showBorders={true}
          rowAlternationEnabled={true}
          onRowUpdated={(e) => {
            this.handleUpdateUser(e.data)
          }}
          onRowInserted={(e) => {
            this.handleAddUser(e.data)
          }}
          onInitNewRow={(e) => e.data.stare = true}
        >
          <Toolbar>
            <Item location="before">
              <div style={{ width: 150 }}>Administrare utilizatori</div>
            </Item>

            <Item name="addRowButton" />
          </Toolbar>

          <Editing
            mode="popup"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={true}
            useIcons={true}
          />

          <Column caption="Nume" dataField="nume" alignment="center" />
          <Column caption="Prenume" dataField="prenume" alignment="center" />
          <Column caption="Email" dataField="email" alignment="center" />

          <Column caption="Nume utilizator" dataField="numeUtilizator" alignment="center">
            <RequiredRule />
          </Column>

          <Column caption="Parola" dataField="parola" visible={false} alignment="center">
            <RequiredRule />
          </Column>

          <Column caption="Grup" dataField="grup" alignment="center">
            <Lookup dataSource={this.context.store.globalData.groups} displayExpr="denumireGrup" valueExpr="idGrupuri" />
            <RequiredRule />
          </Column>

          <Column caption="Activ" dataField="stare" dataType="boolean" alignment="center" />

          <Scrolling mode="virtual" />
        </DataGrid>

        <LoadPanel
          position={{ my: "center", at: "center" }}
          visible={this.state.loading}
          shading={true}
          showPane={true}
        />
      </>
    )
  }
}

export default UserSettings