import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './Login/Login'
import Register from './Register/Register'

export default class Auth extends Component {
	render() {
		return (
			<Switch>
				<Route path="/auth/login" component={Login} />
				<Route path="/auth/register" component={Register} />
				<Redirect exact from="/auth" to="/auth/login" />
			</Switch>
		)
	}
}
