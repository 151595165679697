import React, { Component } from 'react'
import { AppContext } from '../../../context/AppContext'
import DataGrid, { Button, Column, Summary, TotalItem, Lookup, Editing, Scrolling, Toolbar, Item as ToolbarItem, RequiredRule, MasterDetail } from 'devextreme-react/data-grid'
import Form, { SimpleItem, ButtonItem, Item } from 'devextreme-react/form'
import { SelectBox, Button as GeneralButton } from 'devextreme-react'
import { LoadPanel } from 'devextreme-react/load-panel'
import axios from 'axios'

import styles from './AdminPanel.module.css'

export default class AdminPanel extends Component {
	static contextType = AppContext

	constructor(props) {
		super(props)
	
		this.state = {
			dataSource: [],
			datagridAllowEditing: true,
			filterVisible: false,
			dateList: [],
			loading: false,
			adminDateInfo: {
				allData: false,
				dataStart: new Date(),
				dataStop: new Date(),
				shift: 0
			}
		}
	}

	getData = () => {
		this.setState({
			loading: true
		}, () => {
			let dStart = this.state.adminDateInfo.dataStart
			let dStop = this.state.adminDateInfo.dataStop
	
			if (dStart > dStop) {
				dStart = dStop
	
				this.setState({
					adminDateInfo: {
						dataStart: dStart,
						dataStop: dStop
					}
				})
			}
	
			const link = `/api/Valori/AfisareValori/${this.state.adminDateInfo.shift}/${dStart.toISOString().split("T")[0]}/${dStop.toISOString().split("T")[0]}/true`
			const dateLink = "/api/Valori/DateCuValori"

			axios.all([
				axios.get(link),
				axios.get(dateLink)
			]).then(([response, dateResponse]) => {
				this.setState({
					dataSource: response.data,
					dateList: dateResponse.data.map(d => (new Date(d)).getTime()),
					loading: false
				})
			}).catch(err => {
				console.log("AdminPanel getData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

	handleUpdateData = (data) => {
		this.setState({
			loading: true,
			datagridAllowEditing: true
		}, () => {
			if (data["__KEY__"] !== undefined) delete data["__KEY__"]

			axios.post("/api/Valori/EditareAdaugareValori", data).then(response => {
				this.getData()
			}).catch(err => {
				console.log("AdminPanel handleUpdateData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

	handleDeleteData = (data) => {
		this.setState({
			loading: true
		}, () => {
			axios.delete(`/api/Valori/StergereValori/${data.data.replace("T", " ")}/${data.refSchimb}`).then(response => {
				this.getData()
			}).catch(err => {
				console.log("AdminPanel handleDeleteData err:", err)

				this.setState({
					loading: false
				})
			})
		})
	}

	renderDetail = (e) => {
		return <div>{e.data.data.obs ?? "Fara observatie"}</div>
	}

	componentDidMount = () => {
		this.getData()
	}

	render() {
		return (
			<div style={{ height: '100%' }}>
				<Form
					className={`${styles.adminPanelForm} ${this.state.filterVisible ? styles.showFilter : styles.hiddenFilter}`}
					id="adminDateForm"
					labelMode="floating"
					formData={this.state.adminDateInfo}
					colCount={7}
				>
					{/* <ButtonItem
						verticalAlignment="bottom"
						buttonOptions={{
							text: 'Filtreaza',
							type: 'default',
							onClick: () => this.getData()
						}}
					/> */}

					<Item
						cssClass="allData"
						colSpan={1}
						dataField="allData"
						editorType="dxCheckBox"
						label={{ text: 'Toate datele' }}
						editorOptions={{
							onValueChanged: () => this.getData()
						}}
					/>

					<Item
						colSpan={2}
						dataField="shift"
						editorType="dxSelectBox"
						label={{ text: 'Schimbul' }}
						editorOptions={{
							items: [{ idSchimburi: 0, denumireSchimb: 'Toate schimburile' }, ...this.context.store.globalData.shifts],
							searchEnabled: true,
							valueExpr: 'idSchimburi',
							displayExpr: 'denumireSchimb',
							onValueChanged: () => this.getData()
						}}
					/>
					
					<SimpleItem
						colSpan={2}
						dataField="dataStart"
						label={{ text: 'Data inceput' }}
						editorType="dxDateBox"
						editorOptions={{
							max: this.state.adminDateInfo.dataStop,
							calendarOptions: {
								cellTemplate: (e) => {
									if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
									else return '<span>' + e.text + '</span>'
								}
							},
							onValueChanged: () => this.getData()
						}}
					/>

					<SimpleItem
						colSpan={2}
						dataField="dataStop"
						label={{ text: 'Data sfarsit' }}
						editorType="dxDateBox"
						editorOptions={{
							max: new Date(),
							calendarOptions: {
								cellTemplate: (e) => {
									if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
									else return '<span>' + e.text + '</span>'
								}
							},
							onValueChanged: () => this.getData()
						}}
					/>
				</Form>

				<DataGrid
					height="100%"
					dataSource={
						this.state.adminDateInfo.allData ?
							this.state.dataSource
							:
							this.state.dataSource.filter(item => (item.bucatiBustean && item.volumBustean && (item.volumBustean + item.bucatiBustean > 0)) || Object.keys(item).length > 4)
					}
					columnAutoWidth={true}
					showBorders={true}
					rowAlternationEnabled={true}
					onEditingStart={(e) => {
						const focusOnEdit = () => {
							const editRowKey = e.component.option("editing.editRowKey")
							const rowIndex = e.component.getRowIndexByKey(editRowKey)
							const cellElement = e.component.getCellElement(rowIndex, "bucatiBustean")
							e.component.focus(cellElement)
							e.component.off("contentReady", focusOnEdit)
						}

						this.setState({ datagridAllowEditing: false }, () => {
							e.component.on("contentReady", focusOnEdit)
						})
					}}
					onEditCanceled={() => this.setState({ datagridAllowEditing: true })}
					onRowInserted={(e) => this.handleUpdateData(e.data)}
					onRowUpdated={(e) => this.handleUpdateData(e.data)}
					onRowRemoved={(e) => this.handleDeleteData(e.data)}
					onInitNewRow={(e) => {
						e.data["bucatiBustean"] = 0
						e.data["volumBustean"] = 0
					}}
				>
					<Editing
						mode="row"
						allowUpdating={true}
						allowDeleting={true}
						allowAdding={true}
						useIcons={true}
					/>

					<Toolbar>
						<ToolbarItem
							cssClass={styles.adminPanelFormButton}
							location="before"
						>
							<GeneralButton name="inputPanel" hint="Afiseaza filtrele" icon="filter" onClick={() => this.setState({ filterVisible: !this.state.filterVisible })} />
						</ToolbarItem>

						<ToolbarItem name="addRowButton" />
					</Toolbar>

					<Column
						caption="Data"
						dataField="data"
						dataType="date"
						format="dd.MM.yyyy"
						allowEditing={this.state.datagridAllowEditing}
						alignment="center"
						editorOptions={{
							max: new Date(),
							calendarOptions: {
								cellTemplate: (e) => {
									if (this.state.dateList.includes(e.date.getTime())) return '<span style="font-style:italic;font-weight:bold">' + e.text + '</span>'
									else return '<span>' + e.text + '</span>'
								}
							}
						}}
					>
						<RequiredRule />
					</Column>

					<Column caption="Schimb" dataField="refSchimb" allowEditing={this.state.datagridAllowEditing} alignment="center">
						<Lookup
							dataSource={this.context.store.globalData.shifts}
							displayExpr="denumireSchimb"
							valueExpr="idSchimburi"
						/>
						<RequiredRule />
					</Column>

					<Column caption="Sef schimb" dataField="sefSchimb" /*allowEditing={this.state.datagridAllowEditing}*/ alignment="center">
						<Lookup
							dataSource={this.context.store.globalData.users}
							displayExpr="numeComplet"
							valueExpr="idUtilizatori"
						/>
						<RequiredRule />
					</Column>

					<Column caption="Bustean taiat" alignment="center">
						<Column
							caption="buc"
							dataField="bucatiBustean"
							dataType="number"
							editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
							alignment="center"
						/>

						<Column
							caption="mc"
							dataField="volumBustean"
							dataType="number"
							editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
							alignment="center"
						/>
					</Column>

					<Column caption="Cherestea" alignment="left">
						{this.context.store.globalData.sizeQualities.map((item, index) => (
							<Column caption={item.denumire} key={`${item.denumire}-${index}`} alignment="center">
								<Column caption="Produs" alignment="center">
									<Column
										caption="pachete"
										dataField={`_${item.denumire}_produsPachete`}
										dataType="number"
										editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
										alignment="center"
									/>

									<Column
										caption="mc"
										dataField={`_${item.denumire}_produsVolum`}
										dataType="number"
										editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
										alignment="center"
									/>
								</Column>

								<Column caption="Ramas" alignment="center">
									<Column
										caption="buc"
										dataField={`_${item.denumire}_ramasBuc`}
										dataType="number"
										editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
										alignment="center"
									/>

									<Column
										caption="mc"
										dataField={`_${item.denumire}_ramasVolum`}
										dataType="number"
										editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
										alignment="center"
									/>
								</Column>
							</Column>
						))}
					</Column>

					<Column caption="Panze utilizate" alignment="center">
						{this.context.store.globalData.machinery.map((item, index) => (
							<Column
								caption={item.denumireUtilaj}
								dataField={`utilaj_${item.denumireUtilaj}`}
								dataType="number"
								editorOptions={{ step: 1, min: 0, precision: 0, format: "#0" }}
								key={`${item.denumireUtilaj}-${index}`}
								alignment="center"
							/>
						))}
					</Column>

					<Column caption="Deseuri" alignment="center">
						{this.context.store.globalData.waste.map((item, index) => (
							<Column
								caption={item.denumireDeseu}
								dataField={`deseu_${item.denumireDeseu}`}
								dataType="number"
								editorOptions={{ step: 0.01, min: 0, precision: 2, format: "#,##0.00" }}
								key={`${item.denumireDeseu}-${index}`}
								alignment="center"
							/>
						))}
					</Column>

					<Column type="buttons" fixed={true} fixedPosition="right">
						<Button name="edit" />
						<Button name="delete" />
					</Column>

					<Scrolling mode="virtual" />

					<Summary>
						<TotalItem
							column="volumBustean"
							summaryType="sum"
						/>

						<TotalItem
							column="bucatiBustean"
							summaryType="sum"
						/>

						{this.context.store.globalData.sizeQualities.map((item, index) => (
							<TotalItem
								key={`${item.denumire}-${index}`}
								column={`_${item.denumire}_produsPachete`}
								summaryType="sum"
							/>
						))}

						{this.context.store.globalData.sizeQualities.map((item, index) => (
							<TotalItem
								key={`${item.denumire}-${index}`}
								column={`_${item.denumire}_produsVolum`}
								summaryType="sum"
							/>
						))}

						{this.context.store.globalData.sizeQualities.map((item, index) => (
							<TotalItem
								key={`${item.denumire}-${index}`}
								column={`_${item.denumire}_ramasBuc`}
								summaryType="sum"
							/>
						))}

						{this.context.store.globalData.sizeQualities.map((item, index) => (
							<TotalItem
								key={`${item.denumire}-${index}`}
								column={`_${item.denumire}_ramasVolum`}
								summaryType="sum"
							/>
						))}

						{this.context.store.globalData.machinery.map((item, index) => (
							<TotalItem
								key={`${item.denumireUtilaj}-${index}`}
								column={`utilaj_${item.denumireUtilaj}`}
								summaryType="sum"
							/>
						))}

						{this.context.store.globalData.waste.map((item, index) => (
							<TotalItem
								key={`${item.denumireDeseu}-${index}`}
								column={`deseu_${item.denumireDeseu}`}
								summaryType="sum"
							/>
						))}
					</Summary>

					<MasterDetail
						enabled={true}
						component={this.renderDetail}
					/>
				</DataGrid>

				<LoadPanel
          position={{ my: "center", at: "center" }}
          visible={this.state.loading}
          shading={true}
          showPane={true}
        />
			</div>
		)
	}
}
