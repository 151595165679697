import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Layout from '../Layout/Layout'
import UserPanel from './UserPanel/UserPanel'
import AdminPanel from './AdminPanel/AdminPanel'
import UserSettings from '../Settings/UserSettings'
import AppSettings from '../Settings/AppSettings'
import Reports from '../Reports/Reports'
import Charts from '../Charts/Charts'
import { AppContext } from '../../context/AppContext'

export default class Home extends Component {
  static contextType = AppContext

  render () {
    return (
      <Layout>
        <Switch>
          <Route path="/home/user-panel" component={UserPanel} />
          <Route path="/home/admin-panel" component={AdminPanel} />
          <Route path="/home/users" component={UserSettings} />
          <Route path="/home/settings" component={AppSettings} />
          <Route path="/home/reports" component={Reports} />
          <Route path="/home/chart" component={Charts} />
          <Redirect exact from="/home" to={this.context.store.role === "Administrator" ? "/home/reports" : "/home/user-panel"} />
        </Switch>
      </Layout>
    )
  }
}
